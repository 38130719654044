import { mapState } from "vuex";

export default {
  data() {
    return {
      langList: [
        {
          text: "한국어",
          value: "ko",
        },
        {
          text: "English",
          value: "en",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
    }),
  },
  methods: {
    langChange() {
      // let lang = this.currentLang === "en" ? "ko" : "en";
      this.$i18n.locale = this.currentLang;
      this.$store.commit("store/langChange", this.currentLang);
    },
  },
};
